@import "../../mixins.scss";

th {
    text-align: center;
}

#bets {
    .ant-checkbox-wrapper {
        margin-top: 5px;
    }
    .ant-collapse-header {
        padding: 10px;
    }
    .ant-collapse-content-box {
        padding: 0;
    }
    .ant-form {
        @include flex;
        flex-direction: row;
        gap: 20px;
        overflow-x: auto;
        padding-top: 0;
        padding-right: 20px;
        padding-bottom: 15px;
        padding-left: 20px;
        .ant-segmented {
            margin-top: 25px;
            margin-bottom: 15px;
        }
        // .createdAtInput {
        //     min-width: 240px;
        //     width: 16vw;
        // }
        .ant-form-item {
            margin: 0;
            & > .ant-form-item-row {
                flex-direction: column;
                & > .ant-form-item-label {
                    text-align: left;
                }
            }
            &:first-of-type {
                & > * > .ant-form-item-control {
                    min-width: 300px;
                    width: 20vw;
                }
            }
            &:nth-of-type(2) {
                & > * > .ant-form-item-control {
                    min-width: 200px;
                    width: 13.33vw;
                }
            }
            &:nth-of-type(3) {
                & > * > .ant-form-item-control {
                    min-width: 200px;
                    width: 13.33vw;
                }
            }
            &:nth-of-type(4) {
                & > * > .ant-form-item-control {
                    min-width: 160px;
                    width: 11vw;
                }
            }
            &:nth-of-type(5) {
                & > * > .ant-form-item-control {
                    min-width: 320px;
                    width: 20vw;
                }
            }
        }
        table,
        button {
            height: 46px;
            margin: auto 0;
        }
        table {
            margin-top: 25px;
            cursor: pointer;
        }
        button {
            margin-top: 30px;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            padding-top: 10px;
            & > .ant-form-item > * > .ant-form-item-control > .ant-form-item-control-input {
                width: 350px;
            }
            table {
                margin: 0;
                box-sizing: content-box !important;
            }
            button {
                width: 40%;
                margin: 0;
            }
        }
    }
    .ant-table-wrapper {
        margin-top: 20px;
    }
}
