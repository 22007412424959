@import '../../mixins.scss';

.btn-primary {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(4px);
  border: 1px solid $primary-color;
  background: $primary-color;
  color: #FFFFFF;
  margin-left: 24px;
  line-height: 24px;
  font-weight: 600;
  font-size: 14px;

  &:first-child {
    margin-left: 0;
  }

  div.icon {
    margin-left: 20px;
  }

  &:hover {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    opacity: 0.8;
  }

  &.ant-btn-primary:active, &.ant-btn-primary:focus {
    color: #fff;
    background:  $primary-color;
    border-color:  $primary-color;
  }

  &.secondary-color {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  &.big {
    height: 48px;
  }

  &.small {
    height: 32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;

    div.icon {
      margin-left: 10px;
    }
  }
}