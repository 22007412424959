th {
  text-align: center;
}

#matches .ant-collapse-header {
  padding: 10px;
}

#matches .ant-collapse-content-box {
  padding: 0;
}

#matches .ant-form {
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
}

#matches .ant-form .ant-form-item {
  margin: 0;
}

#matches .ant-form .ant-form-item > .ant-form-item-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#matches .ant-form .ant-form-item > .ant-form-item-row > .ant-form-item-label {
  text-align: left;
}

#matches .ant-form .matchUuid > * > .ant-form-item-control {
  min-width: 300px;
  width: 20vw;
}

#matches .ant-form .league > * > .ant-form-item-control {
  min-width: 300px;
  width: 20vw;
}

#matches .ant-form .matchStatus .ant-radio-button-wrapper {
  width: 30px;
}

#matches .ant-form .startTime > * > .ant-form-item-control {
  min-width: 320px;
  width: 24vw;
}

#matches .ant-form table,
#matches .ant-form button {
  height: 46px;
  margin: auto 0;
}

#matches .ant-form table {
  margin-top: 25px;
  cursor: pointer;
}

#matches .ant-form button {
  margin-top: 30px;
}

@media (max-width: 768px) {
  #matches .ant-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 10px;
  }
  #matches .ant-form > .ant-form-item > * > .ant-form-item-control > .ant-form-item-control-input {
    width: 350px;
  }
  #matches .ant-form table {
    margin: 0;
    -webkit-box-sizing: content-box !important;
            box-sizing: content-box !important;
  }
  #matches .ant-form button {
    width: 40%;
    margin: 0;
  }
}

#matches .ant-table-wrapper {
  margin-top: 20px;
}
