@import "../../mixins.scss";

.MainMenu.ant-menu-horizontal {
	border: none;
	box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.08), inset -1px 0px 0px rgba(0, 0, 0, 0.08);

	&.has-pricing {
		min-width: 720px;
	}

	li.ant-menu-item {
		display: flex;
		align-items: center;
		box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.08), inset -1px 0px 0px rgba(0, 0, 0, 0.08);
		padding: 0;
		margin: 0;
		top: 0;
		width: 100%;

		.ant-menu-title-content {
			// height: 52px;
			padding: 8px 12px;
			min-width: 120px;
		}

		.MenuItemTop {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.icon {
				line-height: 16px;
				height: 16px;
				svg {
					width: 16px;
					height: 16px;
					path {
						fill: $primary-color;
					}
				}
			}
			.ant-badge {
				.ant-badge-count {
					height: 24px;
					min-width: 24px;
					line-height: 24px;
					border-radius: 12px;
					background: #efe6fd;
					box-shadow: 0 0 0 1px #efe6fd;
					color: $primary-color;
					font-weight: 600;
					font-size: 12px;
				.ant-scroll-number-only {
						height: 24px;
						line-height: 24px;
					}
				}
			}
		}

		.MenuItemContent {
			display: flex;
			align-items: center;
			padding-top: 6px;
			a {
				color: #212121;
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
			}
		}

		&.ant-menu-item:hover {
			a {
				color: $primary-color;
			}
			&:after {
				border-bottom: 2px solid $primary-color;
				right: 0;
				left: 0;
			}
		}

		&.ant-menu-item-selected {
			background: $primary-color;

			.MenuItemTop {
				.icon {
					svg path {
						fill: #fff;
					}
				}
				.ant-badge {
					.ant-badge-count {
						background: $primary-color;
						box-shadow: 0 0 0 1px $primary-color;
						color: #fff;
					}
				}
			}

			.MenuItemContent {
				a {
					color: #fff;
				}
			}

			&:hover a {
				color: #fff;
			}

			&:after {
				border-bottom: 2px solid $primary-color;
				right: 0;
				left: 0;
			}
		}
	}

	@media (max-width: 1000px) {
		&.has-pricing {
			min-width: auto;
		}
	}
}
