@import '../../mixins.scss';

.ant-layout {
  height: 100%;
  min-height: 100vh;
}

.ant-layout-sider {
  position: relative;
}

.mobile-toggle-menu {
  position: absolute;
  margin-left: 225px;
  margin-top: 8px;
  z-index: 2;
  transition: all 0.2s;

  button {
    padding: 0;
    width: 34px;
    height: 34px !important;
    color:#212121;
    @include border-radius(4px);

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.hide {
    margin-left: 24px;
  }
}

.button-collapse {
  span {
    color: $primary-text;
    position: absolute;
    right: -14px;
    top: 5px;
    z-index: 9;
    font-size: 14px;
    background: #fff;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
  }
}

.ant-menu-item-icon {
  padding-top: 12px;
}

.side-menu-component {
  .logo {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    img {
      width: 100%;
    }
  }

  .ant-layout-sider-children {
    padding-top: 12px;
    padding-bottom: 24px;
  }

  .ant-menu {
    margin-top: 13px;
  }
  
  .ant-menu-item {
    opacity: 1;
    transition: opacity 0.3s;

    @include flex;
    @include align-items(center);

    svg {
      margin-right: 16px;
    }
  }

  .ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
    padding-left: 18px !important;
  }

  .ant-menu-submenu-vertical {
    .sub-title {
      padding: 0;
      position: relative;
      top: 3px;
      left: -4px;
    }
  }
  .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }

  .ant-menu-item:hover, .ant-menu-item-selected, .ant-menu-item-active {
    color: $secondary-color;

    a {
      color: $secondary-color;
    }
  }

  .ant-menu-item-icon {
    width: 24px;
  }
  .ant-menu-item-selected {
    .ant-menu-title-content {
      color: $primary-color;
      &::after {
        display: none !important;
      }
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      > .ant-menu-title-content {
        font-weight: bold;
        color: $primary-color !important;
      }
    }
    .ant-menu-item-selected {
      .ant-menu-title-content {
        color: $primary-color !important;
        font-weight: bold;
      }
    }
    .ant-menu-item .ant-menu-item-only-child {
      .ant-menu-title-content {
        color: $primary-text !important;
      }
    }

  }
  
  .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    margin-left: 6px;
    margin-right: 0;
    &::after {
      display: none;
    }
  }

  .ant-menu-submenu {
    opacity: 1;
    transition: opacity 0.3s;

    svg {
      margin-right: 16px;
    }

    .ant-menu-submenu-title {
      @include flex();
      @include align-items(center);
    }

    .ant-menu-item.ant-menu-item-only-child {
      padding-left: 10px !important;
    }

    .ant-menu-inline {
      background: transparent;
    }
  }
  
  .ant-menu-inline-collapsed {
    @include flex;
    @include justify-content(center);
    @include align-items(center);
    flex-direction: column;

    .ant-menu-item {
      width: 100%;
      padding: 0 !important;
      border-radius: 4px;
      opacity: 0;

      .ant-menu-item-icon {
        padding-left: 6px;
        padding-top: 6px;
      }

      @include justify-content(center);
      svg {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .ant-menu-submenu {
      opacity: 0;
    }
  }
 
  
  .ant-menu-submenu {
    margin: 0 12px;
    margin-bottom: 8px;
    .ant-menu-sub {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-left: 16px;
      
      .ant-menu-title-content {
        padding-left: 10px;
      }
              
      .ant-menu-item {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .ant-menu-submenu-title {
      margin: 0;
      padding-left: 8px !important;
    }

    .ant-menu-item-selected {
      background: transparent !important;
    }
  }
  .ant-menu-item-disabled {
    .ant-menu-title-content {
      color: #ffffff !important;
    }
  }
  .ant-menu-title-content {
    @media screen and (max-width: 767px) {
      color: #212121;
    }
  }

  .ant-menu-submenu-open {
    background-color: #ffffff;
    border-radius: 10px;
    .ant-menu-title-content {
      color: $primary-text;
    }
  }
}
