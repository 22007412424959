body {
  background: #F4F4F4;
  .SignInPage, .SignUpPage, .ForgotPasswordPage, .ResetPasswordPage {
    margin-top: 50px;
    margin-bottom: 50px;

    .LeftImg {
      height: 100%;
    }
    @media only screen and (max-width: 425px) {
      margin: 0px;
    }
  }

  .SignInPage .LeftImg {
    min-height: 625px;
  }

  .SignUpPage .LeftImg {
    min-height: 675px;
  }

  .SetupAccountPage .LeftImg {
    min-height: 555px;
  }
}