th {
  text-align: center;
}

#bets .ant-checkbox-wrapper {
  margin-top: 5px;
}

#bets .ant-collapse-header {
  padding: 10px;
}

#bets .ant-collapse-content-box {
  padding: 0;
}

#bets .ant-form {
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
}

#bets .ant-form .ant-segmented {
  margin-top: 25px;
  margin-bottom: 15px;
}

#bets .ant-form .ant-form-item {
  margin: 0;
}

#bets .ant-form .ant-form-item > .ant-form-item-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#bets .ant-form .ant-form-item > .ant-form-item-row > .ant-form-item-label {
  text-align: left;
}

#bets .ant-form .ant-form-item:first-of-type > * > .ant-form-item-control {
  min-width: 300px;
  width: 20vw;
}

#bets .ant-form .ant-form-item:nth-of-type(2) > * > .ant-form-item-control {
  min-width: 200px;
  width: 13.33vw;
}

#bets .ant-form .ant-form-item:nth-of-type(3) > * > .ant-form-item-control {
  min-width: 200px;
  width: 13.33vw;
}

#bets .ant-form .ant-form-item:nth-of-type(4) > * > .ant-form-item-control {
  min-width: 160px;
  width: 11vw;
}

#bets .ant-form .ant-form-item:nth-of-type(5) > * > .ant-form-item-control {
  min-width: 320px;
  width: 20vw;
}

#bets .ant-form table,
#bets .ant-form button {
  height: 46px;
  margin: auto 0;
}

#bets .ant-form table {
  margin-top: 25px;
  cursor: pointer;
}

#bets .ant-form button {
  margin-top: 30px;
}

@media (max-width: 768px) {
  #bets .ant-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 10px;
  }
  #bets .ant-form > .ant-form-item > * > .ant-form-item-control > .ant-form-item-control-input {
    width: 350px;
  }
  #bets .ant-form table {
    margin: 0;
    -webkit-box-sizing: content-box !important;
            box-sizing: content-box !important;
  }
  #bets .ant-form button {
    width: 40%;
    margin: 0;
  }
}

#bets .ant-table-wrapper {
  margin-top: 20px;
}
