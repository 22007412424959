th {
  text-align: center;
}

#leagues .ant-collapse button {
  height: 46px;
  margin-top: 30px;
}

#leagues .ant-collapse .ant-radio-group {
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#leagues .ant-collapse .acceptBet .ant-radio-button-wrapper {
  width: 30px;
}

#leagues .ant-collapse-header {
  padding: 10px;
}

#leagues .ant-collapse-content-box {
  padding: 0;
}

#leagues .ant-form {
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
}

#leagues .ant-form .ant-form-item {
  margin: 0;
}

#leagues .ant-form .ant-form-item > .ant-form-item-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#leagues .ant-form .ant-form-item > .ant-form-item-row > .ant-form-item-label {
  text-align: left;
}

#leagues .ant-form .name > * > .ant-form-item-control {
  min-width: 300px;
  width: 20vw;
}

#leagues .ant-form table {
  margin-top: 25px;
  cursor: pointer;
}

@media (max-width: 768px) {
  #leagues .ant-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 10px;
  }
  #leagues .ant-form > .ant-form-item > * > .ant-form-item-control > .ant-form-item-control-input {
    width: 350px;
  }
  #leagues .ant-form table {
    margin: 0;
    -webkit-box-sizing: content-box !important;
            box-sizing: content-box !important;
  }
  #leagues .ant-form button {
    width: 40%;
    margin: 0;
  }
}

#leagues .ant-table-wrapper {
  margin-top: 20px;
}

#leagues .ant-table-wrapper .ant-form {
  padding: 0;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

#leagues .ant-table-wrapper .ant-form .ant-radio-group {
  padding: 0;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#leagues .ant-table-wrapper .ant-form .acceptBet .ant-radio-button {
  width: 40px;
}
