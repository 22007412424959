@import '../mixins.scss';

.signin-container {
  margin-top: 120px;
  height: 100vh;
  display: flex;
  min-width: 440px;
  justify-content: center;
  .Logo {
    text-align: center;
    padding: 80px 0 40px;
  }

  .form-container {
    margin: 0 auto;

    @include flex;
  }

  .SignInForm {
    width: 390px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    margin-top: 110px;
  }

  .ant-form-item-explain {
    min-height: auto;
  
    div[role='alert'] {
      font-size: 10px;
      min-height: 12px;
    }
  
    &.ant-form-item-explain-error {
      div[role='alert'] {    
        position: absolute;
        bottom: 10px;
        left: 0;
      }
    }
  
    &.ant-form-item-explain-success {
      div[role='alert'] {
        position: absolute;
        bottom: -34px;
        left: 0;
      }
    }
  }

  @media (max-width: 991px) {
    .content-page {
      max-width: 768px;
      margin: 0 auto;
    }

    .Logo {
      padding: 40px 0 40px;
    }
  }
}