@import "../../mixins.scss";

.MainLayout {
  .ant-layout-header  {
    padding: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f2f5;
    position: static;
    width: 100%;
    z-index: 999;
    height: 52px;
    align-items: center;

    .logo {
      padding-left: 16px;
      img {
        max-width: 300px;
        height: 36px;
      }

      span {
        margin-left: 10px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 4px 7px;
        background-color: #fff;
        color: #1E88E5;
        border-radius: 11px;
      }
    }

    .account-info {
      display: flex;
      padding: 0 10px;
      .avatar {
        width: 32px;
        margin-left: 10px;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .info {
        margin: auto 0;
        padding: 10px;
        .fullname {
          line-height: 16px;
          font-size: 12px;
          font-weight: 600;
        }
        .email {
          line-height: 16px;
          font-size: 10px;
          cursor: pointer;
        }
      }

      .ant-badge {
        margin: auto 10px;
        margin-right: -15px;
        .ant-badge-count {
          height: 24px;
          min-width: 24px;
          line-height: 24px;
          border-radius: 12px;
          .ant-scroll-number-only {
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .ant-layout-content {
    .Content {
      margin: 0 50px;
      .ContentHeader {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        .HeaderTitle {
          font-weight: 600;
          font-size: 14px;
          line-height: 48px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          height: 48px;
          a, .Link {
            color: $primary-color;
          }
          .icon > div {
            display: flex;
            align-items: center;
            height: 48px;
          }
        }
        .HeaderToolBar {
          .ToolBarButtons {
            display: flex;
            .ant-btn {
              height: 48px;
              margin-left: 20px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              line-height: 24px;
              font-weight: 600;
              font-size: 14px;

              .icon {
                height: 24px;
                margin-left: 20px;
              }
            }

            .FilterButton {
              border: 1px solid #E0E0E0;
              background: #FFFFFF;
              color: #212121;
              .icon {
                svg path {
                  fill: #212121;
                }
              }

              &.Open {
                border-bottom: none;
                box-shadow: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                height: 56px;
              }
            }            
          }
        }
      }
      .ContentErrors {
        background: #FDEFEF;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 24px;
        margin-top: -10px;
        .errorDetail {
          font-size: 12px;
          line-height: 16px;
          padding: 4px 0;
          color: #E53935;
          &:before {
            content: '\2022';
            display: inline-block;
            color: #E53935;
            padding: 0 6px 0 0;
          }
        }
      }
      .ContentFilters {
        .FilterForm {
          padding: 24px;
          background: #fff;
          margin-bottom: 24px;
          margin-top: -20px;

          label {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #212121;
          }

          .FilterFormActions {
            display: flex;
            justify-content: flex-end;
            .ant-btn {
              width: 120px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-right: 20px;
              color: $primary-color;

              .icon {
                height: 24px;
                margin-left: 20px;
              }

              &.ant-btn-primary {
                background: #fff;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          &.CompaniesFilterForm, &.CompanyAdminsFilterForm {
            .FilterFormActions {
              padding-top: 30px;
              @include justify-content(flex-start !important);
            }
          }
        }
      }
      .ContentMain {
        background: #fff;
        width: 100%;
        .ant-table-wrapper {
          .ant-table-content, .ant-table-container {
            .ant-table-body {
              &::-webkit-scrollbar {
                -webkit-appearance: none !important;
                background-color: transparent !important;
                width: 12px !important;
                height: 12px !important;
              }

              &::-webkit-scrollbar-thumb {
                background-clip: padding-box !important;
                background-color: #E5E5E5 !important;
                border-color: transparent !important;
                border-radius: 4px !important;
                border-style: solid !important;
                border-width: 4px !important;
              }

              &::-webkit-scrollbar-thumb:hover {
                background-color: rgba(0, 0, 0, 0.5) !important;
              }
            }
            .group, .user {
              padding: 4px 12px;
              background: #F4F4F4;
              border: 1px solid #E0E0E0;
              box-sizing: border-box;
              border-radius: 16px;
              margin: 0 2px;
            }
            .actions {
              display: flex;
              justify-content: center;
              align-items: center;
              .ant-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                height: 32px !important;

                .icon {
                  height: 24px;
                  margin-left: 5px;
                  color: $primary-color;

                  svg {
                    width: 24px;
                    height: 24px;
                  }
                }

                &.edit {
                  color: $primary-color;
                }

                &.delete {
                  color: #D32F2F;
                }

                &.draft {
                  background: #888888;
                }

                &.dispatched {
                  background: #FFAB00;
                }

                &.optimized, &.optimize {
                  background: #D32F2F;
                }

                &.error {
                  background: #F6D5D5;
                  color: #D32F2F;
                }
                &.invite-user {
                  color: #00C853;
                  .icon {
                    svg path {
                      fill: #00C853;
                    }
                  }
                }
                &.manage-users {
                  color: #1E88E5;
                  .icon {
                    svg path {
                      fill: #1E88E5;
                    }
                  }
                }
              }
            }
          }
          .ant-pagination {
            li {
              &:first-child {
                flex: 1;
              }
              &:last-child {
                flex: 1;
                div {
                  float: right;
                }
              }
            }

            .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
              border: none;
              a {
                border: 1px solid $primary-color;
                border-radius: 2px;
              }
            }

            .ant-pagination-item:hover a, .ant-pagination-item-active a {
              background-color: $primary-color;
              color: #fff;
            }

            .ant-select-selector {
              border-radius: 4;
              background: #F4F4F4;
              height: 32px !important;
              .ant-select-selection-item {
                height: 32px !important;
                line-height: 32px !important;
              }
            }
          }
        }
      }
      &.ContentFull {
        margin: 0;
        .ContentMain {
          padding: 0;
        }
      }
    }
  }
}

.errorMessage {
  color: #d32f2f;
}

.popupErrorMessages {
  .item {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 0;
  }
}

.errorMessages {
  .item {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 0;
  }
}

.status {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  border-radius: 2px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 120px;
  min-width: 80px;
  &.error, &.skipped {
    background: #FFFFFF;
    border: 1px solid #d32f2f;
    color: #d32f2f;
  }
  &.draft, &.geocoding {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
  }
  &.unassigned, &.unscheduled {
    background: #ebebeb;
    color: #888888;
  }
  &.pickup_arrived, &.delivery_arrived {
    background: #daecfb;
    color: #1E88E5;
  }
  &.in_transit, &.optimized, &.going_next {
    background: #f9efdb;
    color: #FFAB00;
  }
  &.completed {
    background: #daf3e4;
    color: #00C853;
  }
  &.failed, &.delivery_failed, &.pickup_failed {
    background: #f6e1e1;
    color: #E53935;
  }
  &.delivered {
    background: #daf3e4;
    color: #00C853;
  }
  &.undelivered {
    background: #f6e1e1;
    color: #E53935;
  }
}

.messageLogStatus {
  display: flex;
  align-items: center;
  justify-items: center;
  .icon {
    height: 24px;
  }
}

.react-tag-input {
  min-height: 48px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  ::placeholder {
    color: #888888;
    opacity: 0.5;
  }
  .react-tag-input__tag {
    background: #F4F4F4;
    border-radius: 16px;
    height: 32px;
    padding: 6px 16px;
    .react-tag-input__tag__content {
      font-size: 14px;
      line-height: 20px;
      padding: 0;
      padding-right: 10px;
    }
    .react-tag-input__tag__remove {
      background: transparent;
      color: #888888;
      font-size: 14px;
      padding-right: 0;
      height: 10px;
      width: 10px;
      &:before {
        background: #888888;
        height: 12px;
      }
      &:after {
        background: #888888;
        height: 12px;
      }
    }
  }
}

.ant-dropdown {
  .MenuNewOrders {
    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #212121;

      .icon {
        height: 16px;
        margin-left: 10px;
      }
    }
  }
}

.ant-modal {
  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .ant-modal-body {
    .FormHeader {
      line-height: 22px;
      padding-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        margin-left: -24px;
        border-left: 4px solid $primary-color;
        padding-left: 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
      }
      .right {
        a {
          display: flex;
          .icon > div {
            line-height: 24px;
            height: 24px;
            padding-left: 12px;
          }
        }
      }
    }

    .FormContent {
      label {
        font-weight: 600;
        font-size: 12px;
      }

      .item-label {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;

        .right {
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #888888;
        }
      }
    }

    .FormFooter {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      padding-left: 24px;
      padding-right: 24px;
      margin-left: -24px;
      margin-right: -24px;
      box-shadow: 0px -1px 0px #f4f4f4;

      .ant-btn {
        height: 48px !important;
        border: none !important;
        border-radius: 5px !important;
        box-shadow: none;
        font-weight: 600;
      }

      .Left {
        .delete {
          border: 1px solid #E53935 !important;
        }
      }

      .Right {
        display: flex;
        .ant-btn {
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icon {
            height: 24px;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.CurrentRoutePlan {
  border: 1px solid #E0E0E0;
  background: #FFFFFF;
  color: #212121;
  padding: 10px 16px;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: bold;
  }
  label {
    color: #888;
  }
  .right {
    display: flex;
    align-items: center;
    > div {
      margin-left: 20px;
    }
  }
  button {
    border: 1px solid $primary-color;
    color: $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      margin-left: 5px;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}
