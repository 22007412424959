@import '../mixins.scss';

.scan-authenticator-code-container {
  .content-page {
    padding: 0 16px;
    width: 100vw;
    height: 100vh;
    @include flex;
    @include align-items(center);
    @include justify-content(center);

    .action-page {
      margin-top: 24px;
      text-align: center;

      button {
        margin-top: 24px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}